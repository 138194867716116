<template>
  <table-view
    ref="tableView"
    url="/dispatch/quantityNumber/listForPage"
    :filterable="false"
    :filter-form="dataForm"
    :exportable="isAuthed('dispatch_quantity_export')"
    export-url="/dispatch/quantityNumber/download"
    :confirmed="isAuthed('dispatch_quantity_confirm')"
    confirm-url="/dispatch/amount/confirm"
    export-file-name="调运方量表"
    :headers="headers"
    show-index
    :split-index="9"
    selectable
    single
  >
    <el-form slot="search" @keyup.enter.native="query" inline>
      <el-form-item label="生产日期">
        <el-date-picker type="date" v-model="dataForm.startDate" value-format="yyyy-MM-dd"></el-date-picker>
      </el-form-item>
      <el-form-item label="到">
        <el-date-picker type="date" v-model="dataForm.endDate" value-format="yyyy-MM-dd"></el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-select v-model="dataForm.confirmStatus" placeholder="请选择确认状态">
          <el-option v-for="b in statusList" :key="b.value" :label="b.name" :value="b.value"></el-option>
        </el-select>
      </el-form-item>
      <el-button @click="query" type="primary">查询</el-button>
    </el-form>
    <template slot="insertion">
      <el-table-column
        header-align="center"
        align="center"
        :width="200"
        prop="statementQuantityNumber"
        label="结算泵量">
        <template slot-scope="{row}">
          <input :value="row.statementQuantityNumber" @focus="clearNumber" @change="changeNumber" @blur="submitNumber(row)" />
        </template>
      </el-table-column>
    </template>
  </table-view>
</template>

<script>
import TableView from '@/components/templates/table-view'

export default {
  name: 'dispatch-amount',

  components: { TableView },

  data () {
    return {
      number: null,
      headers: [
        { id: 1, prop: 'dispatchTime', label: '生产日期', width: 150 },
        { id: 2, prop: 'mixingTable', label: '拌站', width: 100 },
        { id: 3, prop: 'insider', label: '局内局外', width: 100 },
        { id: 4, prop: 'constructionUnit', label: '施工单位', width: 150 },
        { id: 5, prop: 'siteName', label: '工程项目', minWidth: 150 },
        { id: 6, prop: 'number', label: '分布分项', width: 120 },
        { id: 8,
          prop: 'carGasType',
          label: '类型',
          width: 80,
          formatter: (row, column, cellValue) => {
            if (cellValue === '1') {
              return '汽'
            } else if (cellValue === '2') {
              return '固'
            } else if (cellValue === '3') {
              return '外汽'
            } else if (cellValue === '4') {
              return '外固'
            }
          }
        },
        { id: 9, prop: 'carSize', label: '泵别', width: 80 },
        { id: 10, prop: 'quantityNumber', label: '上传泵量', width: 100 },
        { id: 11,
          prop: 'carType',
          label: '备注(类型)',
          width: 100,
          formatter: (row, column, cellValue) => {
            if (cellValue == '1') {
              return '自有'
            } else if (cellValue == '2') {
              return '租赁'
            } else if (cellValue == '3') {
              return '外借'
            }
          } },
        { id: 12,
          prop: 'confirmStatus',
          label: '方量确认',
          width: 100,
          formatter: (row, column, cellValue) => {
            if (cellValue == 5) {
              return '已完成'
            } else {
              return '待完成'
            }
          } }
      ],
      dataForm: {
        startDate: '',
        endDate: ''
      },
      statusList: [{
        name: '待确认',
        value: 4
      }, {
        name: '已完成',
        value: 5
      }]
    }
  },

  methods: {
    clearNumber () {
      this.number = null
    },
    changeNumber (e) {
      this.number = e.target.value
    },
    submitNumber (row) {
      console.log(this.number)
      if (this.number != '' && row.submitNumberuantityNumber != this.number) {
        this.$confirm('确定将方量修改为(' + this.number + ')吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$http({
            url: this.$http.adornUrl('/dispatch/quantityNumber/updatenumber'),
            method: 'post',
            data: { 'dispatchTaskId': row.dispatchTaskId, 'correctedQuanitityNumber': this.number }
          }).then((data) => {
            if (data && data.code === 200) {
              this.$opts.success()
              this.$refs.tableView.queryData()
            }
          })
        }).catch(() => { })
      } else if (this.number != null) {
        this.$refs.tableView.queryData()
      }
    },
    query () {
      this.$refs.tableView.queryData()
    }
  }
}
</script>
